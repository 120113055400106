input[type=text],
input[type=email],
input[type=search],
input[type=password] 
{
  background-color: #FFFFFF;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  box-sizing: border-box;
  color: #02222B;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 10px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: #fff url('../assets/icon_chevron.svg') no-repeat right 10px center;
  background-size: 15px;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: #02222B;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 600;
  padding: 6px 28px 6px 16px;
  position: relative;
}

/* .frm-btn {
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: #02222B;
  cursor: pointer;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 16px;
  width: 48%;
}

.frm-btn.cta {
  background: #58DBAC;
  border: 1px solid #58DBAC;
  color: #02222B;
} */

.select-wrapper {
  display: flex;
  flex: 1;
  position: relative;
}


/* The input checkbox slider */
.switch {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 36px;
}

.switch input {
  height: 0;
  opacity: 0;
  width: 0;
}

.slider {
  -webkit-transition: .4s;
  background-color: #F2F4F7;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  -webkit-transition: .4s;
  background-color: white;
  bottom: 2px;
  content: "";
  height: 16px;
  left: 2px;
  position: absolute;
  transition: .4s;
  width: 16px;
}

input:checked + .slider {
  background-color: #58DBAC;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


/*** time input container ***/
/* .time-input-container {
  position:relative;
}

.time-input-container select {
  background: url('../assets/icon_clock.svg') 15px center no-repeat;
  background-size: 17px 17px;
  color: #02222B;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 10px 10px calc(100% - 75px);
  width: 100%;
}

.time-input-container span {
  color: #02222B;
  font-size: 16px;
  font-weight: 500;
  left: 42px;
  line-height: 24px;
  pointer-events: none;
  position: absolute;
  top: 11px;
} */


/*** date field container ***/
.date-input-container {
  position: relative;
  z-index: 100;
}

.date-input-container input {
  background: url('../assets/icon_event.svg') 15px center no-repeat;
  background-size: 20px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 10px 10px 44px;
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}


/*** temperature box ***/
/* .temperature-box-container .temperature-box-header {
  padding:0 0 6px 0;
}

.temperature-box-container .temperature-box {
  box-sizing: border-box;
  color: #02222B;
  display: block;
  height: 64px;
  margin: 0 auto;
  overflow: hidden;
  padding: 4px 8px;
  width: 76px;
}

.temperature-box-container .selected-temperature {
  float: left;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin: 10px 0 0 0;
}

.temperature-box-container .increment-temp, .temperature-box-container .decrement-temp {
  margin:0 0 0 36px;
  padding: 4px 0;
}

.temperature-box-container .increment-temp input {
  background:#FFFFFF url('../assets/icon_plus.svg') no-repeat center center;
  background-size: 12px 12px;
}
.temperature-box-container .decrement-temp input  {
  background: #FFFFFF url('../assets/icon_minus.svg') no-repeat center center;
  background-size: 12px 12px;
}

.temperature-box-container .increment-temp input, .temperature-box-container .decrement-temp  input {
  border: 1px solid #D0D5DD;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  height: 20px;
  text-align: center;
  width: 20px;
} */

/*** widget ***/
.frm-widget {
  border: 1px solid #EAECF0;
  border-radius: 12px;
  overflow: hidden;
  width: 248px;
}

.frm-widget-header {
  background: #F9FAFB;
  border-bottom: 1px solid #EAECF0;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 12px 24px;
}