.modal-overlay {
  -webkit-backdrop-filter: blur(8px);
  background: rgba(52, 64, 84, 0.70);
  backdrop-filter: blur(8px);
  bottom: 0;
  display: flex;
  justify-content: center;
  left:0;
  overflow: hidden;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  top:0;
  z-index: 101;
}

.modal {
  display: flex;
  background: #FFF;
  border-radius: 12px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  box-sizing: border-box;
  flex-flow: column;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  height: auto;
  justify-content: space-between;
  max-width: 350px;
  overflow-y: scroll;
  position: absolute;
  top: calc(100% - 90vh);
  width: 80vw;
}

.modal.desktop {
  left: calc(50% + 44px);
  max-width: 800px;
  overflow: hidden;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
}

.modal .modal-content {
  max-height: 55vh;
  overflow: hidden;
  overflow-y: auto;
  padding: 12px 18px;
}

.modal .modal-header-container {
  align-items: center;
  display: flex;
  padding: 12px;
}

.modal .modal-header-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.modal .modal-header-container .modal-image {
  height: 18px;
  padding: 0 10px;
  width: 18px;
}

.modal .modal-header-container .modal-title {
  align-items: center;
  color: #02222B;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.modal .modal-header-container .close-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

/* .modal .modal-header-container .modal-description {
  color: #02222B;
  font-size: 13px;
  line-height: 20px;
} */

.modal .modal-footer {
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.modal .modal-footer .modal-btn {
  background: white;
  border: 2px solid #EDF1F2;
  border-radius: 8px;
  color: #02222B;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
  transition: ease-in-out background 250ms;
  width: 49%;
  
  &:hover {
    background: #EDF1F2;
    transition: ease-in-out background 250ms;
  }
}

.modal .modal-footer .modal-btn.cta {
  background: #02222B;
  border: 2px transparent solid;
  color: white;

  &:hover {
    background: #02222B;
    transition: ease-in-out background 250ms;
  }
}

.modal .modal-footer input:disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.modal .current-zone-temperature {
  font-size: 56px;
  line-height: 56px;
  font-weight: 600;
  float: right;
  letter-spacing: -1.44px;
  color: #02222B;
}

.modal.desktop .modal-homepage-zone-settings.modal-temporary-adjustment {
  max-width: 300px;
  margin: 0 auto;
}