section.events {
    color: #02222B;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
  }
  
  .events.processing {
    filter: opacity(0.4);
    pointer-events: none;
  }

  /* .events .events-container {
    margin: 50px 52px 30px 90px;
  } */
  
  .events .events-container .main-containers {
    margin-bottom: 30px;
    position: relative;
  }
  
  .events .events-container h1 {
    color: #02222B;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin: 0 0 20px;
  }
  
  .events .events-container .event-header hr {
    background: #EDF1F2;
    border: none;
    height: 2px;
  }
  
  .event-header .title-add-button {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  
  .events .events-container .event-header .title-add-button h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  
  .events .events-container .event-header .title-add-button .add-button {
    align-items: center;
    background: #58DBAC;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    transition: ease-in-out all 250ms;
    width: 24px;
    &:hover {
      background: #0EAA82;
      transition: ease-in-out all 250ms;
    }
  }
  
  .events .events-container .event-header .title-add-button .add-button img {
    filter: brightness(0);
  }
  
  
  /* EVENTS MAIN TABLE STYLES */
  .events-table table {
    /* border: 3px #EDF1F2 solid; */
    border-collapse: separate;
    border-radius: 12px;
    border-spacing: 0;
    margin-top: 18px;
    overflow: hidden;
    width: 100%;
  }
  
  .events-table table td {
    border-bottom: 2px solid #D0D5DD;
  }
  
  .events-table table tr:last-child td {
    border-bottom: none;
  }
  
  .events-table table thead {
    background: #EDF1F2;
  }
  
  .events-table table thead tr th:first-child {
    text-align: left;
    padding: 10px 0 10px 24px;
  }
  
  .events-table table thead tr th {
    font-size: 12px;
    font-weight: 600;
    padding: 10px 0;
  }
  
  .events-table table tbody tr {
    height: 65px;
  }
  
  .events-table table tbody tr td {
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
    text-align: center;
    width: 22%;
  }
  
  .events-table table tbody tr td.mode-container div.mode {
    border-radius: 50px;
    font-weight: 600;
    line-height: 1.25;
    margin: 0 auto;
    padding: 2px;
    width: 90px;
  }
  
  .events-table table tbody tr td.mode-container div.heat {
    background: #FFE6D5;
    color: #E62E05;
  }
  
  .events-table table tbody tr td.mode-container div.cool {
    background: #D1E9FF;
    color: #2E90FA;
  }
  
  .events-table table tbody tr td.mode-container div.fan {
    background: #F2F4F7;
    color: #98A2B3;
  }

  .events-table table tbody tr td.mode-container div.auto {
    background: #EAECF5;
    color: #4E5BA6;
  }
  
  .events-table table tbody tr td:first-child {
    font-weight: 600;
    max-width: 155px;
    padding-left: 24px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
  }
  
  .events-table table tbody tr td:nth-of-type(3),
  .events-table table tbody tr td:nth-of-type(4),
  .events-table table tbody tr td:nth-of-type(5) {
    width: 13%;
  }
  
  .events-table table tbody tr td:last-child {
    width: auto;
  }
  
  .events-table table tbody tr td.edit-delete-container {
    align-items: center;
    display: flex;
    font-weight: 600;
    height: 65px;
    justify-content: space-around;
    min-width: 110px;
    text-decoration: underline;
  }
  
  .events-table table tbody tr td.edit-delete-container .delete {
   font-weight: 500;
  }
  
  .events-table table tbody tr td.edit-delete-container .delete,
  .events-table table tbody tr td.edit-delete-container .edit {
    cursor: pointer;
    transition: ease-in-out all 250ms;
    &:hover {
      opacity: 0.5;
      transition: ease-in-out all 250ms;
    }
  }

/* @media screen and (min-width: 1400px) {
  .events .events-container {
    margin: 50px auto 90px;
    max-width: 75vw;
  }
} */