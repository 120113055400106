@import-normalize;

:root {
  --font-color: #02222B;
  --background-color: #f8f9fb;
  --link-color: #02222B;
}

[data-theme="dark"] {
  --font-color: white;
  --background-color: #02222B;
  --link-color: white;
}

section.desktop {
  margin: 24px 0 0 114px;
}

section.mobile {
  margin: 60px 0 0 24px;
}

#root, .App, .logging-in-screen {
  display: flex;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  height: 100%;
  width: 100%;
}

html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  width: 100%;
}

body {
  background-color: var(--background-color);
  color: var(--font-color);
}

a {
  color: var(--link-color);
  text-decoration: none;
}

.App {
  display: flex;
  flex-flow: column;
  position: relative;
}

/* HEADERS */
.header-container {
  align-items: center;
  background: #f8f9fb;
  color: #02222B;
  display: flex;
  justify-content: space-between;
  height: 55px;
  position: sticky;
  top: -2px;
  z-index: 50;
}

.header {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 24px;

  h1 {
      color: #02222B;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      margin: 0;
  }

  img {
      padding-right: 8px;
  }
}

/* HIGHCHARTS CSS */
li.highcharts-menu-item {
  font-size: 13px !important;
  font-weight: 500;
}

.highcharts-data-table {
  height: 300px;
  overflow: auto;
  padding: 20px 0 0;
}

.highcharts-data-table table {
  width: 100%;
}

td.highcharts-number {
  text-align: center;
}

/* LIVE/HISTORY GRAPH CSS */
.chart-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 12px 12px 0;
}

.chart-nav .tabs {
  display: flex;
}

.chart-nav .tabs .live,
.chart-nav .tabs .history {
  border-bottom: 4px solid #EAECF0;
  color: #02222B;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.75;
  padding-bottom: 8px;
  text-align: center;
  transition: ease-in-out all 250ms;
  width: 120px;
}

.chart-nav .tabs .live.active,
.chart-nav .tabs .history.active {
  border-bottom: 4px solid #15B79E;
  opacity: 1;
  transition: ease-in-out all 250ms;
}

.chart-nav .refresh {
  background: #02222B;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 0;
  text-align: center;
  transition: ease-in all 150ms;
  width: 130px;
}

@import 'forms.css';
@import 'modal.css';
@import 'schedules.css';
@import 'events.css';
